"use client";

import Image from "next/image";

import Logo from "../../public/logo.png";
import { Login } from "../app/navigate";

export default function Header() {
  return (
    <header className="fixed left-0 right-0 top-0 z-10 flex h-16 items-center gap-4 bg-purple-200 px-4 md:px-6">
      <a className="flex w-60 align-middle" href="/">
        <Image src={Logo} alt="Company Logo" width={200} height={200} />
      </a>
      <div className="flex flex-1 items-center justify-end md:justify-between">
        <nav aria-label="Global" className="hidden md:block">
          <ul className="flex items-center gap-6 text-sm"></ul>
        </nav>

        <div className="flex items-center gap-4">
          <div className="sm:flex sm:gap-4">
            <button
              className="hidden rounded-md bg-purple-900 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-purple-700 md:block"
              onClick={Login}>
              Login
            </button>

            <a
              className="rounded-md bg-gray-100 px-5 py-2.5 text-sm font-medium text-purple-600 transition hover:text-purple-600/75 sm:block"
              href="/contact">
              Contact
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
