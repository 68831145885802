"use client";

export default function Hero() {
  return (
    <section className="hero -mt-16 flex h-full flex-grow items-center justify-center bg-gradient-to-t from-gray-200 to-purple-200">
      <div className="mx-auto max-w-screen-xl px-4 py-8 text-center lg:px-12 lg:py-9">
        <a
          href="#"
          className="mb-2 inline-flex items-center justify-between rounded-full px-1 py-1 text-lg">
          {/* <span className=" px-4 text-2xl font-medium text-black">
              Bringing Order to Chaos
            </span> */}
        </a>
        <h1 className="mb-5 text-5xl leading-none tracking-tight text-black md:text-7xl">
          Review legal records 10x faster and 5x cheaper.
        </h1>
        <p className="mb-8 text-lg font-normal text-gray-800 sm:px-16 lg:text-xl xl:px-48">
          Understand how case documents impact your ability to win.
        </p>
        <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-x-4 sm:space-y-0">
          <a
            href="/contact"
            className="bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 inline-flex items-center justify-center rounded-lg px-5 py-3 text-center text-base font-medium text-black focus:ring-4">
            Get Started
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
          </a>
          <a
            href="/contact"
            className="inline-flex items-center justify-center rounded-lg border border-gray-300 bg-gray-100 px-5 py-3 text-center text-base font-medium text-gray-900 hover:bg-gray-200 focus:ring-4 focus:ring-gray-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mr-3 h-6 w-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
              />
            </svg>
            Get a Demo
          </a>
        </div>
      </div>
    </section>
  );
}
